<template>
  <div class="box">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>仓储管理</el-breadcrumb-item>
      <el-breadcrumb-item>配件调配</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 内容开始 -->
    <el-card class="box-card">
      <div>
        <el-button type="primary" @click="opentan()">公共仓库调配</el-button>
      </div>
      <!-- 待办列表 -->
      <h3>货物调配待办</h3>
      <el-table :data="daitiaoshen" stripe style="width: 100%">
        <el-table-column prop="order_no" label="货物调配编号"></el-table-column>
        <el-table-column prop="apply_time" label="发起时间"></el-table-column>
        <el-table-column prop="auth_step_desc" label="流程进度"></el-table-column>
        <el-table-column prop="order_type_desc" label="调库类型"></el-table-column>
        <el-table-column prop="input_house_name" label="调入仓库"></el-table-column>
        <el-table-column prop="output_house_name" label="调出仓库"></el-table-column>
        <el-table-column prop="apply_username" label="申请人"></el-table-column>
        <el-table-column prop="auth_username" label="审批人"></el-table-column>
        <el-table-column prop="adjust_status_desc" label="状态"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="daieditfun(scope.row.id)">详 情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageDatas" @newsize="bianzhis" @newpape="bianyes" />

      <h3>货物调配记录</h3>
      <el-table :data="tableData" stripe style="width: 100%">
        <el-table-column prop="order_no" label="货物调配编号"></el-table-column>
        <el-table-column prop="apply_time" label="发起时间"></el-table-column>
        <el-table-column prop="order_type_desc" label="调库类型"></el-table-column>
        <el-table-column prop="input_house_name" label="调入仓库"></el-table-column>
        <el-table-column prop="output_house" label="调出仓库"></el-table-column>
        <el-table-column prop="user_name" label="申请人"></el-table-column>
        <el-table-column prop="auth_username" label="审批人"></el-table-column>
        <el-table-column prop="adjust_status_desc" label="状态"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="editfun(scope.row.id)">详 情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />

      <!-- 新建弹窗 -->
      <el-dialog title="新建公共仓库调配" :visible.sync="dialogFormVisible" :before-close="morencles">
        <div class="tan">
          <div class="let">申请人</div>
          <div class="el-select"><el-input v-model="shenname" disabled></el-input></div>
        </div>
        <el-form :model="form" :rules="rules">
          <el-form-item label="调入仓库" label-width="78px" prop="house_id">
            <el-select v-model="form.house_id" placeholder="未选择" clearable>
              <el-option v-for="item in storelist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批人" label-width="78px">
            <el-select v-model="form.auth_user" placeholder="未选择">
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- 动态增加表格单行 -->
          <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
              <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAddZJ">新增</el-button>
            </el-col>
            <el-col :span="1.5"></el-col>
            <!-- {{ form }} -->
            <el-table :data="form.items" ref="zztable" :row-class-name="zzTableRowClassName" @cell-click="cellHandleclick">
              <el-table-column label="序号" type="index" width="50" />
              <el-table-column prop="name" label="货物名称">
                <template slot-scope="scope">
                  <el-autocomplete
                    :goods_id="'fItem' + scope.row.index"
                    :trigger-on-focus="false"
                    placeholder="请输入内容"
                    v-model="scope.row.name"
                    :fetch-suggestions="validateCounts"
                    value-key="name"
                    @select="handleSelect($event)"
                    :debounce="300"
                    @focus="focusSelect(scope.row.index)"
                  ></el-autocomplete>
                </template>
              </el-table-column>
              <el-table-column prop="no" label="货物名称"></el-table-column>
              <el-table-column prop="desc" label="货物详情"></el-table-column>
              <el-table-column label="调出仓库">
                <template slot-scope="scope">
                  <div id="test">{{ scope.row.house_name }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_num" label="库存数量"></el-table-column>
              <el-table-column prop="require_num" label="需求数量">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.require_num" min="0" type="number" placeholder="请输入需求数量"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="180" align="center">
                <template slot-scope="scope">
                  <!-- 修改按钮 -->
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="enddelete(scope.$index, form.items)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </div>
        <el-dialog width="30%" title="库存数量" :visible.sync="innerVisible" append-to-body>
          <el-table :data="gridData" @select="xuanSelect" ref="tablelist">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column property="house_name" label="仓库名称" width="150"></el-table-column>
            <el-table-column property="goods_num" label="库存数量" width="200"></el-table-column>
          </el-table>
        </el-dialog>
      </el-dialog>

      <!-- 记录详情弹窗 -->
      <el-dialog title="公共仓库调配详情" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" center>
        <el-form :model="dform">
          <el-form-item label="申请人" label-width="78px" prop="house_id">
            <div class="el-select"><el-input v-model="dform.shen" disabled></el-input></div>
          </el-form-item>
          <el-form-item label="调入仓库" label-width="78px">
            <el-select v-model="dform.house_id" placeholder="未选择" clearable>
              <el-option v-for="item in storelist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批人" label-width="78px">
            <el-select v-model="dform.auth_user" placeholder="未选择">
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table :data="item_list" style="width: 100%">
          <el-table-column type="expand" style="left: 20px">
            <template slot-scope="props">
              <el-table :data="props.row.list" id="table">
                <el-table-column prop="batch_no" label="批次号" width="180"></el-table-column>
                <el-table-column prop="goods_name" label="货物名称" width="180"></el-table-column>
                <el-table-column prop="goods_desc" label="货物详情"></el-table-column>
                <el-table-column prop="goods_num" label="数量"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column prop="goods_no" label="货物编号"></el-table-column>
          <el-table-column prop="goods_name" label="商品名称"></el-table-column>
          <el-table-column prop="goods_desc" label="商品详情"></el-table-column>
          <el-table-column prop="input_house_name" label="调入仓库"></el-table-column>
          <el-table-column prop="out_house_name" label="调出仓库"></el-table-column>
          <el-table-column prop="goods_num" label="库存数量"></el-table-column>
        </el-table>
        <!-- 流程 -->
        <div class="nav">
          <h3>审批流程</h3>
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item, index) in auth_list" :key="index" :name="item.id" :title="item.type">
              <div class="shen">
                <div class="shen-left">
                  <div>{{ item.type }}:{{ item.user_name }}</div>
                  <div>提交时间：{{ item.time }}</div>
                </div>
                <div class="shen-right">
                  {{ item.auth_status }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>

      <!-- 待办弹窗详情+审核 -->
      <el-dialog title="公共仓库调配详情" :visible.sync="daidialogVisible" width="50%" :before-close="daihandleClose" center>
        <el-form>
          <el-form-item label="申请人" label-width="78px">
            <div class="el-select"><el-input v-model="daiapply_user.user_name" disabled></el-input></div>
          </el-form-item>
          <el-form-item label="调入仓库" label-width="78px">
            <el-select v-model="daiinput_house.id" placeholder="未选择" clearable>
              <el-option v-for="item in storelist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批人" label-width="78px">
            <el-select v-model="daiauth_user.auth_userid" placeholder="未选择">
              <el-option v-for="item in renlist" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-table :data="daiitem_list" style="width: 100%">
          <el-table-column type="index" width="50" label="序号"></el-table-column>
          <el-table-column prop="goods_no" label="货物编号"></el-table-column>
          <el-table-column prop="goods_name" label="商品名称"></el-table-column>
          <el-table-column prop="goods_desc" label="商品详情"></el-table-column>
          <el-table-column prop="input_house_name" label="调入仓库"></el-table-column>
          <el-table-column prop="out_house_name" label="调出仓库"></el-table-column>
          <el-table-column prop="goods_num" label="可用数量"></el-table-column>
          <el-table-column prop="require_num" label="需求数量"></el-table-column>
        </el-table>
        <!-- 流程 -->
        <div class="nav">
          <h3>审批流程</h3>
          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(item, index) in daiauth_list" :key="index" :name="item.id" :title="item.type">
              <div class="shen">
                <div class="shen-left">
                  <div>{{ item.type }}:{{ item.user_name }}</div>
                  <div>提交时间：{{ item.time }}</div>
                </div>
                <div class="shen-right">
                  {{ item.auth_status }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="daidialogVisible = false">关 闭</el-button>
          <el-button type="success" @click="tong">通 过</el-button>
          <el-button type="danger" @click="ju">拒 绝</el-button>
        </span>
        <el-dialog width="30%" title="审批" :visible.sync="tonginnerVisible" append-to-body :before-close="tongHas">
          <div class="tan">
            <div class="lets">审核备注</div>
            <div class="el-select"><el-input v-model="tongremark"></el-input></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="tongquxiao">取 消</el-button>
            <el-button type="primary" @click="tongque">确定</el-button>
          </span>
        </el-dialog>
        <el-dialog width="30%" title="审批" :visible.sync="juinnerVisible" append-to-body :before-close="jutongHas">
          <div class="tan">
            <div class="lets">审核备注</div>
            <div class="el-select"><el-input v-model="juremark"></el-input></div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="juquxiao">取 消</el-button>
            <el-button type="primary" @click="juque">确定</el-button>
          </span>
        </el-dialog>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye.vue'
import { InfoGet } from '../../../api/register'
import { Shopsou, Cangxuan, Huofen, Shentiao, Shenheren, Shenpeilist, Daoshenxiang, Daibantiao, Daixiang, Diaoshen } from '../../../api/store'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      juinnerVisible: false,      // 拒绝弹窗
      tonginnerVisible: false,        // 审批弹窗
      order_id: '',               // 调仓申请单id
      status: '',                 // 审核状态   1 通过   2拒绝
      tongremark: '',             // 通过审核备注
      juremark: '',               // 拒绝审核备注
      daidialogVisible: false,
      daiapply_user: {},
      daiinput_house: {},
      daiauth_user: {},
      daiauth_list: [],
      daiitem_list: [],
      activeNames: ['1'],
      item_list: [],               // 详情货物信息
      auth_list: [],               // 详情审核流程
      dialogVisible: false,        // 详情弹窗
      dialogFormVisible: false,    // 新建公共仓库调配弹窗
      innerVisible: false,
      shenname: '',                // 申请人
      storelist: [],               // 仓库列表
      shoplist: [],                // 商品搜索列表
      tableData: [],               // 列表数据
      daitiaoshen: [],             // 待办列表
      focusIndex: '',
      form: {
        order_type: 1,             // 公共仓库
        house_id: '',              // 调入仓库
        auth_user: '',             // 审核人id
        items: []
      },
      dform: {
        shen: '',
        house_id: '',
        auth_user: ''
      },
      renlist: [],                 // 人员列表
      gridData: [],                // 货品可以列表
      // 表单验证规则
      rules: {
        house_id: [{ required: true, message: '请选择调入仓库', trigger: 'change' }]
      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      pageDatas: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      canghouse_id: 0
    }
  },
  mounted() {
    this.InfoGets() // 获取申请人
    this.Cang()
    this.Getrenlist()
    this.Shenpeilists()
    this.Daibantiaolist()
  },
  methods: {
    // 新建
    opentan() {
      this.dialogFormVisible = true
      // console.log('公共仓库调配')
    },

    // 点击通过
    tong() {
      this.status = 1
      this.tonginnerVisible = true
    },

    // 拒绝
    ju() {
      this.status = 2
      this.juinnerVisible = true
    },

    // 通过取消
    tongquxiao() {
      this.tongremark = ''
      this.tonginnerVisible = false
    },

    juquxiao() {
      this.juremark = ''
      this.juinnerVisible = false
    },

    tongHas() {
      this.tongquxiao()
    },

    jutongHas() {
      this.juquxiao()
    },

    // 确认通过审核
    tongque() {
      this.Diaoshentong()
    },

    // 拒绝审核
    juque() {
      this.Diaoshenju()
    },

    // 点击货物调配记录详情按钮
    editfun(id) {
      console.log(id)
      this.dialogVisible = true
      this.Daoshenxiangs(id)
    },

    // 点击待办列表详情按钮
    daieditfun(id) {
      console.log(id)
      this.daidialogVisible = true
      this.order_id = id
      this.Daixianglist(id)
    },

    // 详情弹窗关闭
    handleClose() {
      this.dialogVisible = false
    },

    // 待办详情关闭
    daihandleClose() {
      this.daidialogVisible = false
    },

    // 确定
    confirm() {
      for (let i = 0; i < this.form.items.length; i++) {
        if (Number(this.form.items[i].require_num) <= Number(this.form.items[i].goods_num)) {
          console.log(this.form.items[i].require_num, this.form.items[i].goods_num)
          this.Shentiaos()
          return
        } else {
          console.log(this.form.items[i].require_num, this.form.items[i].goods_num)
          this.$message({
            message: '需求数量不能超过库存数量',
            type: 'warning'
          })
          return
        }
      }
    },

    // 取消
    cancel() {
      this.innerVisible = false
      this.dialogFormVisible = false
      this.form.house_id = ''
      this.form.auth_user = ''
      this.form.items = []
      this.form.order_type = 1
      this.form.user = ''
      this.gridData = []
      this.canghouse_id = 0
    },

    // 默认关闭
    morencles() {
      this.cancel()
    },

    // 点击调出仓库单元格触发的事件
    cellHandleclick(row, column, cell, event) {
      if (column.label === '调出仓库') {
        console.log(row, column)
        // this.Huofenlist(row.goods_id)
        this.innerVisible = true
      } else {
        this.innerVisible = false
      }
    },

    xuanSelect(selection, val) {
      if (selection.length > 1) {
        let del_row = selection.shift()
        this.$refs.tablelist.toggleRowSelection(del_row, false)
      }
      this.canghouse_id = val.house_id
      console.log(this.canghouse_id)
      this.form.items[this.focusIndex - 1].house_name = val.house_name
      this.form.items[this.focusIndex - 1].goods_num = val.goods_num
      this.form.items[this.focusIndex - 1].output_house = val.house_id
      this.form.items[this.focusIndex - 1].batch_sum = val.batch_sum
      this.innerVisible = false
      this.gridData = []

      setTimeout(() => {
        let list = []
        this.form.items.forEach(item => {
          list.push(item.batch_sum) // item.batch_sum 检查是否重复的数据
        })
        let temp = this.isRepeat(list) // 重复则返回true，否则为false
        if (temp) {
          this.$confirm('请勿添加重复的调库配件', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {})
            .catch(() => {})

          this.form.items.pop()
        }
        // console.log(list, temp, '优先级', this.form.items)
      }, 200)
    },

    isRepeat(arr) {
      var hash = {}
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true
        }
        hash[arr[i]] = true
      }
      return false
    },

    // 删除
    enddelete(index, rows) {
      rows.splice(index, 1)
    },

    // 搜索选中
    handleSelect(e, term) {
      console.log(e)
      this.form.items[this.focusIndex - 1].no = e.no
      this.form.items[this.focusIndex - 1].desc = e.desc
      this.form.items[this.focusIndex - 1].goods_id = e.value
      this.Huofenlist(e.value)
    },

    focusSelect(index) {
      // console.log(index)
      this.focusIndex = index
    },

    //新增专家行
    handleAddZJ() {
      let obj = {
        house_name: '',
        goods_num: '',
        output_house: '',
        batch_sum: ''
      }
      this.form.items.push(obj)
    },

    //添加行号
    zzTableRowClassName({ row, rowIndex }) {
      row.index = rowIndex + 1
    },

    // 搜商品的数据
    validateCounts(queryString, cb) {
      this.Drops(queryString, cb)
    },

    // 选中采购人
    shenhandleSelect(e, val) {
      console.log(e, val)
      this.form.auth_user = e.value
    },

    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Shenpeilists()
    },

    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Shenpeilists()
    },
    bianzhis(newSize) {
      // 分页值变化
      this.pageDatas.size = newSize
      this.Daibantiaolist()
    },

    bianyes(newPape) {
      // 分页页数变化
      this.pageDatas.page = newPape
      this.Daibantiaolist()
    },

    // 仓库搜索
    async Cang() {
      const { data } = await Cangxuan({ is_public: 1 })
      this.storelist = data.data
    },

    // 获取申请人
    async InfoGets() {
      const { data } = await InfoGet()
      this.shenname = data.data.name
    },

    // 商品搜索
    async Drops(name, cd) {
      const { data } = await Shopsou({ name })
      // console.log(data.data)
      this.shoplist = data.data
      cd(data.data)
    },

    // 获取可以货品列表
    async Huofenlist(value) {
      const { data } = await Huofen({ goods_id: value, house_id: this.canghouse_id })
      // console.log(data)
      this.gridData = data.data
      if (this.gridData.length == 0) {
        // console.log('sss')
        this.form.items.pop()
        this.$confirm('选中仓库没有此配件', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {})
          .catch(() => {})
      }
    },

    async Shentiaos() {
      const { data } = await Shentiao({ ...this.form })
      // console.log(data)
      this.getanpostfun(data, '申请', this.morencles(), this.Shenpeilists(), this.Daibantiaolist())
    },

    // 人员列表
    async Getrenlist() {
      const { data } = await Shenheren({ is_manager: 1 })
      this.renlist = data.data
    },

    // 配件调仓申请列表
    async Shenpeilists() {
      const { data } = await Shenpeilist({ ...this.pageData, order_type: 1 })
      this.tableData = data.data.data
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },

    // 配件申请详情
    async Daoshenxiangs(id) {
      const { data } = await Daoshenxiang({ id: id })
      // console.log(data)
      this.dform.shen = data.data.apply_user.user_name
      this.dform.house_id = data.data.input_house.id
      this.auth_list = data.data.auth_list
      this.item_list = data.data.item_list
      this.dform.auth_user = data.data.auth_user.auth_userid
    },

    // 待办详情
    async Daixianglist(id) {
      const { data } = await Daixiang({ id: id })
      // console.log(data)
      this.daiapply_user = data.data.apply_user
      this.daiinput_house = data.data.input_house
      this.daiauth_user = data.data.auth_user
      this.daiauth_list = data.data.auth_list
      this.daiitem_list = data.data.item_list
    },

    // 待办调仓申请列表
    async Daibantiaolist() {
      const { data } = await Daibantiao({ ...this.pageDatas })
      // console.log(data)
      this.daitiaoshen = data.data.data
      this.pageDatas.count = data.data.count
      this.pageDatas.page = data.data.page * 1
    },

    // 通过审核
    async Diaoshentong() {
      const { data } = await Diaoshen({ status: 1, remark: this.tongremark, order_id: this.order_id })
      // console.log(data)
      this.getanpostfun(data, '审核通过', this.Daibantiaolist(), this.tongHas(),this.daihandleClose())
    },
    // 拒绝审核
    async Diaoshenju() {
      const { data } = await Diaoshen({ status: 2, remark: this.juremark, order_id: this.order_id })
      // console.log(data)
      this.getanpostfun(data, '拒绝', this.Daibantiaolist(), this.jutongHas(),this.daihandleClose())
    },

    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style scoped>
.let {
  width: 55px;
  margin-left: 15px;
}
.tan {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
#test:hover {
  cursor: pointer;
  color: red;
}
#test {
  text-decoration: underline;
  text-decoration-color: rgba(3, 37, 255, 0.3);
  text-underline-offset: 3px;
}
.shen {
  display: flex;
  align-items: center;
}
.shen-left {
  width: 40%;
}
.shen-right {
  width: 50%;
  text-align: right;
}
.nav {
  width: 60%;
  margin-left: 20%;
}

.box {
  width: 100%;
  height: 100vh;
}
.lets {
  width: 85px;
}
</style>